/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import { createContext, useContext, useMemo, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'
import { UserAccountsService } from '../services'
import { getSubscriptionByUser } from '../services/subscriptionService'

const AuthContext = createContext()

// const mocks = {
//   firstLogin: true,
// }

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useLocalStorage('userData', null)
  const [token, setToken] = useLocalStorage('token', null)
  const [clientData, setClientData] = useLocalStorage('clientData', null)
  const [quickRegistration, setQuickRegistration] = useState(false)
  const [sirstLoginAux, setFirstLoginAux] = useState(false)
  // const [firstLogin, setFirstLogin] = useLocalStorage('firstLogin', false)
  // const [userPlan, setUserPlan] = useLocalStorage('plans', false)

  const navigate = useNavigate()

  const login = async (data) => {
  
    if (data?.data) {
      console.log('data?.data', data)

      setUserData(data.data)
      setToken(data.data.token)
    } else {
      console.log('data?.data', data)
      setUserData(data)
      setToken(data.token)
    }

    console.log('userdata', userData)
    
    const { data: dataFirstLogin, responseStatus } =
    await UserAccountsService.getUserAccountsByLogin()


    const {data: dataPlans} = await getSubscriptionByUser()

    console.log('dataPlans.length', dataPlans.length)

    // if(dataPlans.length === 0){      
    //   setUserPlan(false)
    // }else{
    //   setUserPlan(true)
    // }

    // setFirstLogin(dataFirstLogin.firstLogin)

    // if (dataFirstLogin.firstLogin) {
    //   //navigate('/WelcomePage', { replace: true })
    //   // navigate('/FastPublishing', {
    //   //   state: { loginRedirect: true },
    //   // })
      
    // } else {
    //   console.log('homepage')
    //   // return <Navigate to="/WelcomePage" replace />
    //   navigate('/WelcomePage')
    // }
  }

  const logout = () => {
    setUserData(null)
    setToken(null)
    setClientData(null)
    // setUserPlan(true)
    // setFirstLogin(false)
    navigate('/', { replace: true })
  }

  const value = useMemo(
    () => ({
      userData,
      login,
      logout,
      quickRegistration,
      setQuickRegistration,
      // firstLogin,
      // setFirstLogin,
      // userPlan,
      // setUserPlan
    }),
    [userData,]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
