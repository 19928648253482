import { useEffect, useState } from 'react'
import { BoxChecket, BoxNotChecket } from '../../../assets/svgs/icons'
import styles from './styles.module.css'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import {
  Login,
  StatesService,
  UserAccountsService,
  UserService,
} from '../../../services'
import { useAuth } from '../../../hooks/useAuth'
import { motion } from 'framer-motion'
import InputMask from 'react-input-mask'


const STEPS = {
  STEP_PASS_CPF: 'stepCPF',
  STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
}

function StepAddress({
  setStep,
  cpf,
  cnpj,
  fantasyName,
  corporateReason,
  userData,
  setUserAccountData,
  pass,
  email,
  wordsArray,
  phone,
  setUserData,
  setQuickRegistration,
}) {
  const { login } = useAuth()
  const [cep, setCep] = useState('')
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [stateOption, setStateOption] = useState('')
  const [stateData, setStateData] = useState([])
  const [logradouro, setLogradouro] = useState('')


  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    const cepModified = cep.replace(/\D/g, '')
    if (cepModified.length === 8) getAddress(cepModified)
  }, [cep])

  const getAddress = async (cep) => {
    
    try {
      const url = `https://viacep.com.br/ws/${cep}/json/`
      const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
          'content-type': 'application/json;charset=utf-8',
        },
      }
      const result = await fetch(url, options)
      const endereco = await result.json()

      if (!endereco.erro) {
        const end = `${endereco.logradouro}, ${endereco.bairro}`
        setStreet(end)
        setCity(endereco.localidade)
        stateData.map((item) => {
          if (item.sigla === endereco.uf) setStateOption(item.id)
        })
      }
    } catch (e) {
      return null
    }
  }

  const loadData = async () => {
    let statesReturn = await StatesService.getStates()

    setStateData(statesReturn.data)
  }

  function onlyNumbers(input) {
    return input.replace(/[^0-9]/g, '')
  }

  const handleChangeState = (event) => {
    setStateOption(event.target.value)
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      //setStep(STEPS.STEP_PASS_ADDRESS)
      const [firstName, ...rest ] = wordsArray;

      let bodyUser = {
        name: firstName,
        surname: rest.toString().replace(/,/g, ' '),
        phone: phone,
        email: email,
        password: pass,
        knowledgeChannelId: 0,
      }

      const UserReturn = await UserService.postUser(bodyUser)

      

      if (
        UserReturn?.responseStatus != 200 &&
        UserReturn?.responseStatus != 204
      ) {
        notifyErrorCustom('Erro ao cadastrar usuario.')
        return
      } else {
        notifySuccessCustom('Usuario foi criado com sucesso')
        setUserData(UserReturn?.data)
        setQuickRegistration(true)
      }

      let bodyUserAccounts = {
        userId: UserReturn?.data?.uuid,
        userAccountTypeId: 0,
        cpf: cpf,
        cnpj: onlyNumbers(cnpj),
        fantasyName: fantasyName,
        corporateName: corporateReason,
        cep: onlyNumbers(cep),
        logradouro: street,
        number: onlyNumbers(number),
        complement: complement,
        //state: formCompany ? +stateOption : 100,
        state: Number(stateOption),
        city: city,
      }

      let userAccountsRegister = await UserAccountsService.postUserAccounts(
        bodyUserAccounts
      )

      setUserAccountData(userAccountsRegister)

      handleLogin()

      setStep(STEPS.STEP_PASS_NICHE_AND_OBJECTIVE)
    } else {
      setStep(STEPS.STEP_PASS_CPF)
    }
  }

  const handleLogin = async () => {
    
    let body = {
      email: email,
      password: pass,
    }

    let loginData = await Login.postAuth(body)

    if (loginData.responseStatus == 200) {
      // setLoading(false)
      login(loginData)
    }
    //setQuickRegistration(true)
    // setLoading(false)
  }
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div className={styles.box_page}>
          <div className={styles.box_content}>
            <p style={{ margin: '0px' }}>Cadastro</p>
            <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Criar conta</h1>

            <div className={styles.box_input_name}>
              <p>CEP</p>
              {/* <input
                className={styles.input_name}
                value={cep}
                onChange={(e) => setCep(e.currentTarget.value)}
                type="text"
                placeholder="CEP"
              /> */}
              <InputMask
                className={styles.input_name}
                type="text"
                mask="99999-999"
                placeholder="CEP"
                value={cep}
                onChange={(event) => setCep(event.target.value)}
              />

              {cep && (
                <>
                  <p>Rua</p>
                  <input
                    className={styles.input_name}
                    value={street}
                    onChange={(e) => setStreet(e.currentTarget.value)}
                    type="text"
                    placeholder="Rua"
                  />
                </>
              )}

              {cep && street && (
                <>
                  <p>Numero</p>
                  <input
                    className={styles.input_name}
                    value={number}
                    onChange={(e) => setNumber(e.currentTarget.value)}
                    type="number"
                    placeholder="Numero"
                  />
                </>
              )}
              {cep && street && number && (
                <>
                  <p>Complemento</p>
                  <input
                    className={styles.input_name}
                    value={complement}
                    onChange={(e) => setComplement(e.currentTarget.value)}
                    type="text"
                    placeholder="Complemento"
                  />
                </>
              )}

              {cep && street && number && complement && (
                <>
                  <p>Estado</p>
                  <select
                    className={styles.input_name}
                    onChange={handleChangeState}
                    value={stateOption}
                  >
                    <option value="">Estado</option>
                    {stateData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {cep && street && number && complement && stateOption && (
                <>
                  <p>Cidade</p>
                  <input
                    className={styles.input_name}
                    value={city}
                    onChange={(e) => setCity(e.currentTarget.value)}
                    type="text"
                    placeholder="Cidade"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            Voltar
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            Avançar
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepAddress
