import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import {
  BusinessService,
  PythonService,
  UserService,
  VoiceToneService,
} from '../../../services'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Loading from '../../../components/Loading'

const STEPS = {
  STEP_PASS_IMAGE: 'stepImage',
  STEP_PASS_BPS: 'stepBPS',
}
function StepVoiceTone({ setVoiceTone, voiceTone, setStep }) {
  const [campaign, setCampaign] = useState('')
  const [tones, setTones] = useState([])
  const [toneSelectedId, setToneSelectedId] = useState(undefined)
  const [voiceToneSelected, setVoiceToneSelected] = useState(undefined)
  const [send, setSend] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // carregue os valores de tons vindo do banco de dados caso a resposta seja 200
    ;(async () => {
      const VoiceToneData = await VoiceToneService.getAllVoiceToneByBusiness()

      if (VoiceToneData.responseStatus === 200) {
        if (VoiceToneData.data) {
          setTones(
            VoiceToneData.data.map((item) => ({
              name: item.tone,
              description: item.description,
            }))
          )

          setSend(true)
        }

        if (VoiceToneData.data.length > 0) {
          setToneSelectedId(VoiceToneData.data[0].uuid)
        }
      }
    })()
  }, [])

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (voiceTone === '') {
        notifyErrorCustom('escolha um tom de voz')
        return
      }

      setStep(STEPS.STEP_PASS_BPS)
    } else {
      setStep(STEPS.STEP_PASS_IMAGE)
    }
  }
  const handleVoiceCampaim = () => {
    if (campaign === '') {
      notifyErrorCustom('De uma breve descrição do seu serviço')
      return
    }
    loadVoiceCampaim()
  }

  const loadVoiceCampaim = async () => {
    setLoading(true)
    // const { responseStatus, data } = await ProductService.getProduct()

    // if (responseStatus !== 200) {
    //   setLoading(false)
    //   return
    // }

    let body = {
      campaign: campaign,
      product: '',
    }

    let response = await PythonService.postVoiceCampaign(body)

    if (response) {
      setTones(transform(response?.data?.data))
      //setSend(true)
    }

    setLoading(false)
  }

  function transform(obj) {
    let result = []

    for (let key in obj) {
      result.push({
        name: key,
        description: obj[key],
      })
    }

    return result
  }

  

  const handleChangeCampaim = (e) => {
    setCampaign(e.target.value)
  }

  const saveVoiceTone = async (data) => {
    let body = {
      tone: data.name,
      description: data.description,
    }

    setVoiceToneSelected(body)

    if (!toneSelectedId) {
      await VoiceToneService.postVoiceTone(body)
    } else {
      const id = toneSelectedId
      await VoiceToneService.putVoiceTone(id, body)
    }

    //setLoading(false)

    //setStep(STEPS.STEP_EFFECTS)
  }
  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '-100vw' }}
        transition={{ type: 'tween', duration: 0.5 }}
        style={{
          width: '100%',
          // height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.box_step}>
          <div className={styles.box_page}>
            <p style={{ margin: '0px' }}>Escolha um tom de comunicação</p>
            <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Criar sua publicação</h1>

            <div className={styles.box_input_email}>
              <p>Escreva uma frase sobre a missão do seu produto ou da sua empresa</p>
              <textarea
                className={styles.input_email}
                type="text"
                value={voiceTone}
                placeholder="Somos uma empresa que usa do poder da inteligência artificial para gerar posts alinhados com o negócio e a preferência de nossos clientes!"
                onChange={handleChangeCampaim}
              />
            </div>
            <div className={styles.box_button_send}>
              <button
                className={styles.button_send}
                onClick={() => {
                  handleVoiceCampaim()
                }}
              >
                Enviar
              </button>
            </div>

            <div className={styles.box_opstions_voice_tone}>
              {tones.map((item, index) => (
                <div key={index} className={styles.voice_tone}>
                  <p>Tom: {item.name}</p>

                  <p>{item?.description}</p>

                  <div className={styles.box_button_select}>
                    <button
                      className={styles.button_select}
                      onClick={() => {
                        saveVoiceTone(item)
                      }}
                    >
                      Selecionar
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.box_button}>
            <button
              className={styles.back_button}
              onClick={() => handleNavigate('back')}
            >
              Voltar
            </button>
            {voiceToneSelected && (
              <button
                className={styles.next_button}
                onClick={() => handleNavigate('next')}
              >
                Avançar
              </button>
            )}
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default StepVoiceTone
