/* global FB */
import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
import {
  CheckBadge,
  Clock,
  FacebookIcon,
  InstagramIcon,
  KeyRed,
  LinkedinIcon,
  LockClosed,
  PinterestIcon,
  TikTokIcon,
  TwiterIcon,
} from '../../../../assets/svgs/icons'
import { useNavigate } from 'react-router-dom'

import key_red from '../../../../assets/icons/svg/key_red.svg'

import {
  LinkedinService,
  UserAccountsService,
  TwitterService,
  SocialMediaConnectionService,
  PlanService,
  SubscriptionService,
} from '../../../../services'
import {
  getBusinessByClientId,
  getBusinessByLogin,
} from '../../../../services/business'
import { useClient } from '../../../../hooks/useClient'
// import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { useRedirect } from '../../../../hooks/useRedirectPAge'
import { notifyWarningCustom } from '../../../../utils/notify'

export const SocialMedia = () => {
  const [settingsCompleted, setSettingsCompleted] = useState({
    parameterizationCompleted: true,
  })
  const { clientData } = useClient()

  const navigate = useNavigate()

  const { setRedirect } = useRedirect()

  const [tokenSocialMedia, setTokenSocialMedia] = useState('')
  const [profileNameLinkedin, setProfileNameLinkedin] = useState('')
  const [profileNameTwitter, setProfileNameTwitter] = useState('')
  const [profileNameInstagram, setProfileNameInstagram] = useState('')

  const initFacebookSDK = () => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '387543906967686',
        cookie: true,
        xfbml: true,
        version: 'v20.0',
      })

      FB.AppEvents.logPageView()
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  // const handleNavigate = () => {
  //   if (settingsCompleted?.parameterizationCompleted) {
  //     navigate('/StepsPage')
  //   } else {
  //     navigate('/StepsPage', {
  //       state: { uncompletedSteps: settingsCompleted?.uncompletedSteps },
  //     })
  //   }
  // }

  useEffect(() => {
    // setTokenLinkedin(localStorage.getItem('tokenLinkedin'))
    loadData()
    loadConnectionSocialMedia()
  }, [clientData])

  useEffect(() => {
    initFacebookSDK()
  }, [])

  const loadConnectionSocialMedia = async () => {
    let businessId
    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await getBusinessByLogin()

      businessId = data[0].uuid
    }

    const { data } =
      await SocialMediaConnectionService.getSocialMediaConnections(businessId)

    console.log('data', data)

    if (data.linkedin) {
      const retLinkedin =
        await SocialMediaConnectionService.getSocialMediaByBusinessAndSocialType(
          businessId,
          1
        )
      setProfileNameLinkedin(retLinkedin.data.profileName)
    }
    if (data.twitter) {
      const retTwitter =
        await SocialMediaConnectionService.getSocialMediaByBusinessAndSocialType(
          businessId,
          2
        )
      setProfileNameTwitter(retTwitter.data.profileName)
    }

    if (data.instagram) {
      const retInstagram =
        await SocialMediaConnectionService.getSocialMediaByBusinessAndSocialType(
          businessId,
          4
        )

      setProfileNameInstagram(retInstagram.data.profileName)
    }

    setTokenSocialMedia(data)
  }

  const loadData = async () => {
    const { data, status } =
      await UserAccountsService.getUserAccountsParameterizationCompleted()

    if (status === 200) {
      setSettingsCompleted(data)
    }
  }

  // const handleRedirect = (url, id) => {
  //   setRedirect(url, id) // Substitua '123' pelo ID real que você deseja passar
  // }

  // const navigation = useNavigate()

  const SocialMediaLogin = async (item) => {
    const socialCount =
      await SocialMediaConnectionService.getAllSocialMediaConnectionUserCount()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    if (userSubscription.data.length <= 0 && socialCount.data.length >= 2) {
      notifyWarningCustom(
        'Você atingiu o limite para cadastrar redes sociais, consulte nossos planos para mais informações!'
      )
      return
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'integration_quantity'
      )
      const quantityItemSocial =
        userSubscription.data[0].Plan.PlanItems[index].quantity

      if (socialCount.data.length >= quantityItemSocial) {
        notifyWarningCustom(
          'Você atingiu o limite para cadastrar redes sociais, consulte nossos planos para mais informações!'
        )
        return
      }
    }

    let businessId

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
    } else {
      const { data } = await getBusinessByLogin()
      businessId = data[0].uuid
    }
    if (item === 'linkedin') {
      const { dataL } = await LinkedinService.getLinkedinByBusiness(businessId)

      setRedirect('/MyAccountPage', clientData)

      window.location.href = dataL
    }

    if (item === 'instagram') {
      const stateValue = `instagram,MyAccountPage,${businessId}`
      // const redirectUri = 'https://homolog.postei.ai/SocialMediaRedirect'
      const redirectUri = window.location.origin + '/SocialMediaRedirect'

      console.log('window.location.origin', window.location.origin)

      // return
      const url = `https://www.instagram.com/oauth/authorize?client_id=481808398034661&redirect_uri=${redirectUri}&response_type=code&scope=business_basic,business_manage_messages,business_manage_comments,business_content_publish&state=${stateValue}`

      setRedirect('/MyAccountPage', clientData)
      window.location.href = url
    }

    if (item === 'twitter') {
      const { dataT, responseStatusT } =
        await TwitterService.getTwitterByBusiness(businessId)

      if (responseStatusT != 200 || responseStatusT != 204) {
        console.log('Erro', responseStatusT)
      }

      setRedirect('/MyAccountPage', clientData)

      window.location.href = dataT
    }

    if (item === 'facebook') {
      FB.getLoginStatus(function (response) {
        console.log('facebook', response)
      })

      FB.login(
        function (response) {
          // handle the response
          console.log('Dialog face', response)
        },
        { scope: 'public_profile,email' }
      )
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.page}
    >
      <div className={styles.container_social_media}>
        <div className={styles.box_parameterization_content}>
          <div className={styles.social_media_module}>
            <div className={styles.module}>
              <h3 className={styles.module_title}>Redes Sociais</h3>
            </div>
          </div>
          <div className={styles.container_social_media_content}>
            <div className={styles.card}>
              <div className={styles.icon_field}>
                <InstagramIcon />
              </div>
              <div style={{ width: '100%' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#3E3E3E',
                    fontSize: 8,
                    fontweight: 300,
                  }}
                >
                  Status
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  {!tokenSocialMedia?.instagram ? (
                    <>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                          fontWeight: 500,
                          color: '#FF2F00',
                        }}
                      >
                        Não Conectado
                      </p>

                      <img src={key_red} alt="key_red" style={{ height: 15 }} />
                    </>
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#0FBE00',
                      }}
                    >
                      Conectado como: {profileNameInstagram}
                    </p>
                  )}
                  <KeyRed />
                </div>
              </div>
              <div onClick={() => SocialMediaLogin('instagram')}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 32,
                    gap: 8,
                    border: '1px solid #6A0098',
                    borderRadius: 6,
                    padding: '4px 10px',
                    cursor: 'pointer',
                  }}
                >
                  <p
                    style={{ fontSize: 12, fontWeight: 500, color: '#190027' }}
                  >
                    Login
                  </p>
                  {/* <img src={lock_closed} alt="key_red" style={{ height: 20 }} /> */}
                  <LockClosed />
                </div>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.icon_field}>
                <FacebookIcon />
              </div>
              <div style={{ width: '100%' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#3E3E3E',
                    fontSize: 8,
                    fontweight: 300,
                  }}
                >
                  Status
                </p>
                {
                  // item.connected ? (
                  //   <div
                  //     style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  //   >
                  //     <p
                  //       style={{
                  //         margin: 0,
                  //         fontSize: 12,
                  //         fontWeight: 500,
                  //         color: '#0FBE00',
                  //       }}
                  //     >
                  //       Conectado como: posteiapp
                  //     </p>
                  //   </div>
                  // ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#FF2F00',
                      }}
                    >
                      Não Conectado
                    </p>
                    <img src={key_red} alt="key_red" style={{ height: 15 }} />
                    <KeyRed />
                  </div>
                  // )
                }
              </div>
              <div
              //onClick={() => SocialMediaLogin(item)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 32,
                    gap: 8,
                    border: '1px solid #6A0098',
                    borderRadius: 6,
                    padding: '4px 10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => SocialMediaLogin('facebook')}
                >
                  <p
                    style={{ fontSize: 12, fontWeight: 500, color: '#190027' }}
                  >
                    Login
                  </p>
                  {/* <img src={lock_closed} alt="key_red" style={{ height: 20 }} /> */}
                  <LockClosed />
                </div>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.icon_field}>
                <PinterestIcon />
              </div>
              <div style={{ width: '100%' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#3E3E3E',
                    fontSize: 8,
                    fontweight: 300,
                  }}
                >
                  Status
                </p>
                {
                  // item.connected ? (
                  //   <div
                  //     style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  //   >
                  //     <p
                  //       style={{
                  //         margin: 0,
                  //         fontSize: 12,
                  //         fontWeight: 500,
                  //         color: '#0FBE00',
                  //       }}
                  //     >
                  //       Conectado como: posteiapp
                  //     </p>
                  //   </div>
                  // ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#FF2F00',
                      }}
                    >
                      Não Conectado
                    </p>
                    <img src={key_red} alt="key_red" style={{ height: 15 }} />
                    <KeyRed />
                  </div>
                  // )
                }
              </div>
              <div
              //onClick={() => SocialMediaLogin(item)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 32,
                    gap: 8,
                    border: '1px solid #6A0098',
                    borderRadius: 6,
                    padding: '4px 10px',
                    cursor: 'pointer',
                  }}
                >
                  <p
                    style={{ fontSize: 12, fontWeight: 500, color: '#190027' }}
                  >
                    Login
                  </p>
                  {/* <img src={lock_closed} alt="key_red" style={{ height: 20 }} /> */}
                  <LockClosed />
                </div>
              </div>
            </div>

            {/* Linkeddin */}
            <div className={styles.card}>
              <div className={styles.icon_field}>
                <LinkedinIcon />
              </div>
              <div style={{ width: '100%' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#3E3E3E',
                    fontSize: 8,
                    fontweight: 300,
                  }}
                >
                  Status
                </p>
                {
                  // item.connected ? (
                  //   <div
                  //     style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  //   >
                  //     <p
                  //       style={{
                  //         margin: 0,
                  //         fontSize: 12,
                  //         fontWeight: 500,
                  //         color: '#0FBE00',
                  //       }}
                  //     >
                  //       Conectado como: posteiapp
                  //     </p>
                  //   </div>
                  // ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    {!tokenSocialMedia?.linkedin ? (
                      <div>
                        <p
                          style={{
                            margin: 0,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#FF2F00',
                          }}
                        >
                          Não Conectado
                        </p>

                        <img
                          src={key_red}
                          alt="key_red"
                          style={{ height: 15 }}
                        />
                      </div>
                    ) : (
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                          fontWeight: 500,
                          color: '#0FBE00',
                        }}
                      >
                        Conectado como: {profileNameLinkedin}
                      </p>
                    )}
                    <KeyRed />
                  </div>

                  // )
                }
              </div>
              <div onClick={() => SocialMediaLogin('linkedin')}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 32,
                    gap: 8,
                    border: '1px solid #6A0098',
                    borderRadius: 6,
                    padding: '4px 10px',
                    cursor: 'pointer',
                  }}
                >
                  <p
                    style={{ fontSize: 12, fontWeight: 500, color: '#190027' }}
                  >
                    Login
                  </p>
                  {/* <img src={lock_closed} alt="key_red" style={{ height: 20 }} /> */}
                  <LockClosed />
                </div>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.icon_field}>
                <TikTokIcon />
              </div>
              <div style={{ width: '100%' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#3E3E3E',
                    fontSize: 8,
                    fontweight: 300,
                  }}
                >
                  Status
                </p>
                {
                  // item.connected ? (
                  //   <div
                  //     style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  //   >
                  //     <p
                  //       style={{
                  //         margin: 0,
                  //         fontSize: 12,
                  //         fontWeight: 500,
                  //         color: '#0FBE00',
                  //       }}
                  //     >
                  //       Conectado como: posteiapp
                  //     </p>
                  //   </div>
                  // ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#FF2F00',
                      }}
                    >
                      Não Conectado
                    </p>
                    <img src={key_red} alt="key_red" style={{ height: 15 }} />
                    <KeyRed />
                  </div>
                  // )
                }
              </div>
              <div
              //onClick={() => SocialMediaLogin(item)}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 32,
                    gap: 8,
                    border: '1px solid #6A0098',
                    borderRadius: 6,
                    padding: '4px 10px',
                    cursor: 'pointer',
                  }}
                >
                  <p
                    style={{ fontSize: 12, fontWeight: 500, color: '#190027' }}
                  >
                    Login
                  </p>
                  {/* <img src={lock_closed} alt="key_red" style={{ height: 20 }} /> */}
                  <LockClosed />
                </div>
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.icon_field}>
                <TwiterIcon />
              </div>
              <div style={{ width: '100%' }}>
                <p
                  style={{
                    margin: 0,
                    color: '#3E3E3E',
                    fontSize: 8,
                    fontweight: 300,
                  }}
                >
                  Status
                </p>
                {tokenSocialMedia?.twitter ? (
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#0FBE00',
                      }}
                    >
                      Conectado como: {profileNameTwitter}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#FF2F00',
                      }}
                    >
                      Não Conectado
                    </p>
                    <img src={key_red} alt="key_red" style={{ height: 15 }} />
                    <KeyRed />
                  </div>
                )}
              </div>
              <div onClick={() => SocialMediaLogin('twitter')}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 32,
                    gap: 8,
                    border: '1px solid #6A0098',
                    borderRadius: 6,
                    padding: '4px 10px',
                    cursor: 'pointer',
                  }}
                >
                  <p
                    style={{ fontSize: 12, fontWeight: 500, color: '#190027' }}
                  >
                    Login
                  </p>
                  {/* <img src={lock_closed} alt="key_red" style={{ height: 20 }} /> */}
                  <LockClosed />
                </div>
              </div>
            </div>
            {/* <div
              className="fb-login-button"
              data-width=""
              data-size=""
              data-button-type=""
              data-layout=""
              data-auto-logout-link="false"
              data-use-continue-as="true"
            >
              robson
            </div> */}
          </div>
        </div>
      </div>
    </motion.div>
  )
}
