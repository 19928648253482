import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { UserGroup } from '../../../assets/svgs/icons'
import { getAllBusinessNiche, postBusinessNiche } from '../../../services/businessNicheService'
import {
  postBusiness,
  getBusinessByLogin,
  putBusiness,
} from '../../../services/business'
import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'

import { motion } from 'framer-motion'
import { UserAccountsService } from '../../../services'
import { Navigate } from 'react-router-dom'

const STEPS = {
  STEP_PASS_ADDRESS: 'stepAddress',
  //STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  STEP_PASS_SUBJECT: 'StepSubject',
}
function StepNIcheAndObjective({
  setStep,
  setBusinessAccountData,
  objectiveSelected,
  setObjectiveSelected,
}) {
  const [businessNichesOptions, setBusinessNichesOptions] = useState([])
  const [nicheSelected, setNicheSelected] = useState('')
  const [subNicheSelected, setSubNicheSelected] = useState('')
  const [businessDescription, setBusinessDescriprion] = useState('')

  const objetiveOptions = [
    { label: 'Engajamento', value: '01' },
    { label: 'Vendas', value: '02' },
    { label: 'Identidade', value: '03' },
    { label: 'Campanha', value: '04' },
    { label: 'Intimidade', value: '05' },
    { label: 'Lançamendo', value: '06' },
  ]

  const businessList = [
    { value: 'Advocacia e Serviços Jurídicos' },
    { value: 'Agricultura e Agronegócio' },
    { value: 'Alimentos e Bebidas' },
    { value: 'Alimentos Orgânicos' },
    { value: 'Arte e Cultura' },
    { value: 'Automotivo' },
    { value: 'Barbearia' },
    { value: 'Beleza e Salão' },
    { value: 'Cafeteria' },
    { value: 'Coaching e Desenvolvimento Pessoal' },
    { value: 'Educação' },
    { value: 'Entretenimento' },
    { value: 'Esportes e Fitness' },
    { value: 'Imóveis e Construção Civil' },
    { value: 'Indústria Farma' },
    { value: 'Joias' },
    { value: 'Jogos / Gaming' },
    { value: 'Marketing e Publicidade' },
    { value: 'Moda e Vestuário' },
    { value: 'Móveis e Decoração' },
    { value: 'Pet Care' },
    { value: 'Saúde e Bem-estar' },
    { value: 'Serviços Empresariais' },
    { value: 'Serviços Financeiros' },
    { value: 'Serviços de RH' },
    { value: 'Sustentabilidade e Energia' },
    { value: 'Tecnologia' },
    { value: 'Viagem e Turismo' },
    { value: 'Designers' },
    { value: 'Notícias e Jornalismo' },
    { value: 'Política' },
  ]

  useEffect(() => {

    loadBusinessNiches()

  }, [])

  const loadBusinessNiches = async () => {
    const { data, responseStatus } = await getAllBusinessNiche()

    if (data.length <= 0) {
      for (const niche of businessList) {
        const bodyNiche = {
          name: niche.value,
          description: niche.value,
        }
        await postBusinessNiche(bodyNiche)
      }
      const { data, responseStatus } = await getAllBusinessNiche()

      if (responseStatus === 200) {
        setBusinessNichesOptions(
          data.map((e) => ({ label: e.name, value: e.uuid }))
        )
      }
    } else {
      if (responseStatus === 200) {
        setBusinessNichesOptions(
          data.map((e) => ({ label: e.name, value: e.uuid }))
        )
      }
    }
  }

  const getNicheOpiton = async () => {
    const { data, responseStatus } = await getAllBusinessNiche()

    if (responseStatus === 200) {
      setBusinessNichesOptions(
        data.map((e) => ({ label: e.name, value: e.uuid }))
      )
    }
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (objectiveSelected === '') {
        notifyErrorCustom('Escolha um objetivo para sua postagem')
        return
      }

      if (nicheSelected === '') {
        notifyErrorCustom('Escolha um nicho para sua postagem')
        return
      }

      if (subNicheSelected === '') {
        notifyErrorCustom('Inclua um subnicho para sua postagem')
        return
      }

      createBusiness()

      setStep(STEPS.STEP_PASS_SUBJECT)
    } else {
      notifyErrorCustom("Não é possivel voltar a partir deste ponto")
      //setStep(STEPS.STEP_PASS_ADDRESS)
    }
  }

  const createBusiness = async () => {
    let body = {
      businessNicheId: nicheSelected,
      // businessClientId: 'string',
      // campaignId: 'string',
      // guestId: 'string',
      subNiche: subNicheSelected,
      description: businessDescription,
    }

    const responseBusines = await getBusinessByLogin()

    let businessAccount

    if (responseBusines.data.length > 0) {
      businessAccount = await putBusiness(responseBusines?.data[0]?.uuid, body)
      
      // return
    } else {
      businessAccount = await postBusiness(body)

    }
    //return

    setBusinessAccountData(businessAccount)
  }
  // return ( <div>

  // </div>)
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>Informações Adicionais</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Nicho e Objetivo</h1>

          <div className={styles.container_objective}>
            <p className={styles.module_title}>Defina seu objetivo</p>
            <div className={styles.box_objective}>
              {objetiveOptions.map((objective, index) => (
                <div
                  className={
                    objectiveSelected.value === objective.value
                      ? styles.objective_selected
                      : styles.objective
                  }
                  key={index}
                  onClick={() => setObjectiveSelected(objective)}
                >
                  <div className={styles.box_icon}>
                    <UserGroup
                      color={
                        objectiveSelected.value === objective.value
                          ? '#fff'
                          : '#A7A7A7'
                      }
                    />
                  </div>
                  
                  <p
                    className={
                      objectiveSelected.value === objective.value
                        ? styles.objective_text_selected
                        : styles.objective_text
                    }
                  >
                    {objective.label}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {objectiveSelected && (
            <div className={styles.container_niche}>
              <p className={styles.module_title}>Agora Escolha seu nicho</p>

              <div className={styles.box_niche}>
                {businessNichesOptions.map((niche, index) => (
                  <div
                    className={
                      nicheSelected === niche.value
                        ? styles.niche_selected
                        : styles.niche
                    }
                    key={index}
                    onClick={() => setNicheSelected(niche?.value)}
                  >
                    <p className={styles.niche_label}>{niche.label}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {objectiveSelected && nicheSelected && (
            <div className={styles.container_subniche}>
              <p className={styles.module_title}>Digite abaixo seu subnicho</p>
              <div className={styles.box_input}>
                <input
                  className={styles.input_subniche}
                  type="text"
                  placeholder="Seu subnicho"
                  onChange={(e) => setSubNicheSelected(e.target.value)}
                />
              </div>
            </div>
          )}

          {objectiveSelected && nicheSelected && subNicheSelected && (
            <div className={styles.container_subniche}>
              <p className={styles.module_title}>
                Digite abaixo a descrição do seu negócio
              </p>
              {/* <div className={styles.box_textarea}> */}
                <textarea
                  className={styles.textarea_subniche}
                  type="text"
                  placeholder="Descriçaõ do seu negócio"
                  onChange={(e) => setBusinessDescriprion(e.target.value)}
                />
              {/* </div> */}
            </div>
          )}
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            Voltar
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            Avançar
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepNIcheAndObjective
