import styles from './styles.module.css'
import React, { useRef, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

import posteiLogoColor from '../../assets/logos/postei-logo-color.png'
import posteiLogoTextColor from '../../assets/logos/postei-logo-text-color.png'
import welcomeIcon from '../../assets/icons/svg/welcome.svg'
import {
  UserAccountsService,
  PlanService,
  SubscriptionService,
} from '../../services'
import Loading from '../../components/Loading'
import PlansCatalog from '../../components/PlanCatalog'
import { motion } from 'framer-motion'

function WelcomePage() {
  const navigate = useNavigate()

  const { logout, userData, userPlan } = useAuth()

  const [first, setfirst] = useState({})

  const [loading, setLoading] = useState(false)
  const [showPlans, setShowPlans] = useState(false)

  

  const handleNavigate = () => {
    // createFreePlan()
    navigate('/StepsPage', {
      state: { fastPublication: true, },
    })
  }

  useEffect(() => {
    loadDataUser()
  }, [])
  

  // const createFreePlan = async () => {
  //   const planData = await PlanService.getPlansAllData()
  //   if (!planData) {

  //     let planBody = {
  //       planTypeId: '0',
  //       aiService: 0,
  //       aiServiceTotal: 0,
  //       scheduling: 0,
  //       schedulingTotal: 0,
  //     }

  //     await PlanService.postPlans(
  //       planBody
  //     )
  //   }
  // }
  const loadDataUser = async () => {
    
    setLoading(true)

    const { data, responseStatus } =
      await UserAccountsService.getUserAccountsByLogin()

    const subscription = await SubscriptionService.getSubscriptionByUser()

    if (subscription.data.length > 0) {
      setShowPlans(false)
    } else {
      setShowPlans(true)
    }

    if (userPlan) {
      navigate('/HomePage')
    }
    // if (responseStatus === 200 || responseStatus === 204) {
    //   setfirst(data)
    //   console.log('59 fisrtlogin', data)
    // }

    setLoading(false)
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%', height: '100%' }}
    >
      <div className={styles.page}>
        {showPlans ? (
          <PlansCatalog setShowPlans={setShowPlans} />
        ) : (
          <div className={styles.page}>
            <Loading enable={loading} />
            <div className={styles.postei_logo_field}>
              <img
                src={posteiLogoColor}
                alt="posteiLogo"
                style={{ height: 34 }}
              />
              <img
                src={posteiLogoTextColor}
                alt="posteiLogoText"
                style={{ height: 34 }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: 26,
                  color: '#190027',
                  fontWeight: 500,
                }}
              >
                {`Boas Vindas, ${userData.userName}!`}
              </p>

              <img src={welcomeIcon} alt="welcomeIcon" style={{ height: 260 }} />

              <p
                style={{
                  textAlign: 'center',
                  fontSize: 18,
                  width: 610,
                  color: '#190027',
                }}
              >
                Já que é a primeira vez que você realiza login na nossa
                plataforma, precisamos de algumas informações adicionais sobre
                suas preferências e sobre o seu negócio.
              </p>

              <p
                style={{
                  textAlign: 'center',
                  fontSize: 14,
                  width: 610,
                  color: '#190027',
                  margin: 20,
                }}
              >
                Fique tranquilo, não vai demorar muito! :)
              </p>

              <button
                style={{
                  margin: 4,
                  padding: '10px 20px',
                  borderRadius: 4,
                  backgroundColor: '#6A0098',
                  fontSize: 12,
                  width: 180,
                }}
                onClick={() => handleNavigate()}
              >
                Iniciar Configurações
              </button>
              <button
                style={{
                  margin: 4,
                  padding: '10px 20px',
                  borderRadius: 4,
                  backgroundColor: '#ffff',
                  fontSize: 12,
                  color: '#6A0098',
                  border: '1px solid #6A0098',
                  width: 80,
                }}
                onClick={handleLogout}
              >
                Voltar
              </button>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default WelcomePage
