import { Routes, Route, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'

import SignIn from '../pages/SignIn'
import HomePage from '../pages/HomePage'
import IndividualUser from '../pages/IndividualUser'
import WelcomePage from '../pages/WelcomePage'
import StepsPage from '../pages/StepsPage'
import Agency from '../pages/AgencyPage'
import PlansPage from '../pages/PlansPage'
import PrivacyPolicies from '../pages/PrivacyPolicies'
import AccountSelection from '../pages/AccountSelectionPage'
import CancelPlanPage from '../pages/CancelPlanPage'
import Datepiker from '../pages/Datepiker'
import DefaulterPage from '../pages/DefaulterPage'

import LoginCompletion from '../pages/LoginCompletion'
import LinkedinRedirection from '../pages/LinkedinRedirection'
import NewPostPage from '../pages/NewPostPage'
import SchedulingPage from '../pages/SchedulingPage'
import SchedulePublicationPage from '../pages/SchedulePublicationPage'
import NewPostForm from '../pages/NewPostFormPage'
import NewCampaignForm from '../pages/NewCampaignFormPage'
import UserList from '../pages/UserList'
import TargetAudience from '../pages/TargetAudience'
import Campaign from '../pages/Campaign'
import ProductsPage from '../pages/ProductsPage'
import ProductsPageView from '../pages/ProductsPageView'
import SocialMediaRedirect from '../pages/SocialMediaRedirect'
import FastPublishing from '../pages/FastPublishing'
import NewPublicationPage from '../pages/NewPublicationPage'
import NewPublicationPageCampaign from '../pages/NewPublicationPageCampaign'
import NewPublicationPageMyPublication from '../pages/NewPublicationPageMyPublication'

import { PublicLayout } from './PublicLayout'
import { ProtectedLayout } from './ProtectedLayout'
import { HistoryPage } from '../pages/HistoryPage'
import { DraftPage } from '../pages/DraftPage'
import { PrototypePage } from '../pages/PrototypePage'
import { ClientPage } from '../pages/ClientPage'
import { MyAccountPage } from '../pages/MyAccountPage'

import { DemoPage } from '../pages/DemoPage'
import Settings from '../pages/Settings'
import TimeLinePage from '../pages/TimeLinePage'

export default function RoutesAll() {
  const PublicRoutesLogin = () => {
    return (
      <Route element={<PublicLayout />}>
        <Route path="/" element={<SignIn />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/AccountSelectionPage" element={<AccountSelection />} />
        <Route path="/IndividualUser" element={<IndividualUser />} />
        <Route path="/Agency" element={<Agency />} />
        <Route path="/PrivacyPolicies" element={<PrivacyPolicies />} />
        <Route path="/CancelPlanPage" element={<CancelPlanPage />} />
        <Route path="/FastPublishing" element={<FastPublishing />} />
        <Route path="/DefaulterPage" element={<DefaulterPage />} />
      </Route>
    )
  }

  const PrivateRoutes = () => {
    return (
      <Route element={<ProtectedLayout />}>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/WelcomePage" element={<WelcomePage />} />
        <Route path="/FastPublishing1" element={<FastPublishing />} />
        <Route path="/StepsPage" element={<StepsPage />} />
        <Route path="/LoginCompletion" element={<LoginCompletion />} />
        <Route path="/HomePage" element={<HomePage />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/TimeLinePage" element={<TimeLinePage />} />
        <Route path="/PlansPage" element={<PlansPage />} />
        {/* <Route path="/Datepiker" element={<Datepiker />} /> */}
        <Route path="/NewPostPage" element={<NewPostPage />} />
        <Route path="/SchedulingPage" element={<SchedulingPage />} />
        <Route
          path="/SchedulePublicationPage"
          element={<SchedulePublicationPage />}
        />
        <Route path="/NewPostFormPage" element={<NewPostForm />} />
        <Route path="/NewCampaingFormPage" element={<NewCampaignForm />} />
        <Route path="/History" element={<HistoryPage />} />
        <Route path="/Draft" element={<DraftPage />} />
        <Route path="/UserList" element={<UserList />} />
        <Route path="/TargetAudience" element={<TargetAudience />} />
        <Route path="/Campaign" element={<Campaign />} />
        <Route path="/ProductsPage" element={<ProductsPage />} />
        <Route path="/ProductsPageView" element={<ProductsPageView />} />
        <Route path="/Prototype" element={<PrototypePage />} />
        <Route path="/Clients" element={<ClientPage />} />
        <Route path="/MyAccountPage" element={<MyAccountPage />} />

        <Route path="/DemoPage" element={<DemoPage />} />

        <Route path="/LinkedinRedirection" element={<LinkedinRedirection />} />
        <Route path="/SocialMediaRedirect" element={<SocialMediaRedirect />} />

        <Route path="/NewPublicationPage" element={<NewPublicationPage />} />
        <Route
          path="/NewPublicationPageCampaign"
          element={<NewPublicationPageCampaign />}
        />
        <Route
          path="/NewPublicationPageMyPublication"
          element={<NewPublicationPageMyPublication />}
        />
      </Route>
    )
  }

  return (
    <Routes>
      {PublicRoutesLogin()}
      {PrivateRoutes()}
    </Routes>
  )
}
