import { useState } from 'react'
import styles from './styles.module.css'
import Slider from 'rc-slider'
import {
  BrandingArchetypeService,
  BusinessService,
  EditorialLineService,
  ProductService,
  PythonService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services'
import Loading from '../../../components/Loading'
import { motion } from 'framer-motion'

const STEPS = {
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_PASS_PUBLICATION: 'stepPublication',
}

function StepBPS({ setStep, setBrandingArchetypeData, brandingArchetypeData }) {

  const [loading, setLoading] = useState(false)

  const handleChange = (k, v) => {
    setBrandingArchetypeData((prevState) => ({ ...prevState, [k]: v }))
  }

  const SliderComponent = ({ value, onChange }) => {
    return (
      <div>
        <Slider
          value={value}
          onChange={onChange}
          min={-5}
          max={5}
          trackStyle={{ backgroundColor: '#28003F', height: 6 }}
          railStyle={{ backgroundColor: '#28003F', height: 6 }}
          handleStyle={{
            width: 15,
            height: 15,
            backgroundColor: '#B900FE',
            border: '2.5px solid #FFFF',
            boxShadow: 'none',
          }}
        />
      </div>
    )
  }

  const SliderValueComponent = ({
    leftText,
    rightText,
    sliderValue,
    setSliderValue,
  }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 20, width: 180 }}>
          <p
            style={{
              margin: 0,
              fontSize: 10,
              textAlign: 'end',
              color: '#6a0098',
            }}
          >
            {leftText}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 300,
          }}
        >
          {SliderComponent({
            value: sliderValue,
            onChange: setSliderValue,
          })}
        </div>

        <div style={{ marginLeft: 20, width: 180 }}>
          <p style={{ margin: 0, fontSize: 10, color: '#6a0098' }}>
            {rightText}
          </p>
        </div>
      </div>
    )
  }

  const saveEditorialLine = async () => {
    setLoading(true)

    let retEditorialLine

    let getEditorial = []

    // if (!clientData?.uuid) {
    getEditorial = await EditorialLineService.getEditorialLinebyLogin()

    const business = await BusinessService.getBusinessByLogin()
    const product = await ProductService.getProductByNotBusinessClient()
    const targetAudience =
      await TargetAudienceService.getAllTargetAudienceByNotClientId()
    const voiceTune = await VoiceToneService.getVoiceToneByBusiness(
      business.data[0].uuid
    )


    const data = {
      flow: 'editorial_line',
      prompt: 'editorial_line',
      business_name: business.data[0].description,
      niche: business.data[0].BusinessNiche.name,
      subniche: business.data[0].subNiche,
      business_description: business.data[0].description,
      product: {
        name: product?.data[0]?.name ?? '',
        type: product?.data[0]?.productTypeValue ?? '',
        description: product?.data[0]?.description ?? '',
      },
      target_public: {
        name: targetAudience?.data[0]?.name ?? '',
        gender: targetAudience?.data[0]?.genderValue ?? '',
        social_class:
          targetAudience?.data[0]?.socialClassValue.join(', ') ?? '',
        profession: targetAudience?.data[0]?.profession ?? '',
        region: targetAudience?.data[0]?.stateValue ?? '',
      },
      voice_tone: voiceTune?.data[0]?.tone ?? '',
      voice_tone_description: voiceTune?.data[0]?.description ?? '',
    }


    retEditorialLine = await PythonService.postPromptGPT(data)
    // }
    // else {
    //   getEditorial = await EditorialLineService.getEditorialLineByClient(
    //     clientData?.uuid
    //   )

    //   const business = await BusinessService.getBusinessByClientId(
    //     clientData?.uuid
    //   )
    //   const product = await ProductService.getProductByBusinessClientId(
    //     clientData?.uuid
    //   )
    //   const targetAudience =
    //     await TargetAudienceService.getAllTargetAudienceByClientId(
    //       clientData?.uuid
    //     )
    //   const voiceTune = await VoiceToneService.getVoiceToneByBusiness(
    //     business.data[0].uuid
    //   )


    //   const data = {
    //     flow: 'editorial_line',
    //     prompt: 'editorial_line',
    //     business_name: business.data[0].description,
    //     niche: business.data[0].BusinessNiche.name,
    //     subniche: business.data[0].subNiche,
    //     business_description: business.data[0].description,
    //     product: {
    //       name: product.data[0].name,
    //       type: product.data[0].productTypeValue,
    //       description: product.data[0].description,
    //     },
    //     target_public: {
    //       name: targetAudience.data[0].name,
    //       gender: targetAudience.data[0].genderValue,
    //       social_class: targetAudience.data[0].socialClassValue.join(', '),
    //       profession: targetAudience.data[0].profession,
    //       region: targetAudience.data[0].stateValue,
    //     },
    //     voice_tone: voiceTune.data[0].tone,
    //     voice_tone_description: voiceTune.data[0].description,
    //   }
    //   retEditorialLine = await PythonService.postPromptGPT(data)
    // }

    let bodyEditorial


    // if (clientData?.uuid) {
    //   bodyEditorial = {
    //     bodyJson: retEditorialLine.data.data,
    //     description: 'editorial line',
    //     businessClientId: clientData?.uuid,
    //   }
    // } else {
    bodyEditorial = {
      bodyJson: retEditorialLine?.data?.data,
      description: 'editorial line',
    }
    // }

    if (getEditorial?.data?.length > 0) {
      await EditorialLineService.putEditorialLine(
        getEditorial?.data[0]?.uuid,
        bodyEditorial
      )
    } else {
      await EditorialLineService.postEditorialLine(bodyEditorial)
    }

    setLoading(false)
    setStep(STEPS.STEP_PASS_PUBLICATION)
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      const bpsFemaleOrMale = brandingArchetypeData?.bpsFemaleOrMale || 0
      const bpsSimpleOrComplex = brandingArchetypeData?.bpsSimpleOrComplex || 0
      const bpsSubtleOrExtravagant =
        brandingArchetypeData?.bpsSubtleOrExtravagant || 0
      const bpsClassicOrModern = brandingArchetypeData?.bpsClassicOrModern || 0
      const bpsFriendlyOrDistant =
        brandingArchetypeData?.bpsFriendlyOrDistant || 0
      const bpsPlayfulOrSerious =
        brandingArchetypeData?.bpsPlayfulOrSerious || 0
      const bpsNaturalOrIndustrial =
        brandingArchetypeData?.bpsNaturalOrIndustrial || 0
      const bpsPopularOrElite = brandingArchetypeData?.bpsPopularOrElite || 0
      const bpsStrippedOrSophisticated =
        brandingArchetypeData?.bpsStrippedOrSophisticated || 0
      const bpsRusticOrRefined = brandingArchetypeData?.bpsRusticOrRefined || 0

      await BrandingArchetypeService.postBrandingArchetype({
        bpsFemaleOrMale,
        bpsSimpleOrComplex,
        bpsSubtleOrExtravagant,
        bpsClassicOrModern,
        bpsFriendlyOrDistant,
        bpsPlayfulOrSerious,
        bpsNaturalOrIndustrial,
        bpsPopularOrElite,
        bpsStrippedOrSophisticated,
        bpsRusticOrRefined,
        brandHistoryAndPurpose : '',
        brandCommunicationStyle : '',
        brandCompetitors: '',
        brandValuesAndMission: '',

        layerMin: 0,
        layerMax: 0,
      })

      saveEditorialLine()
    } else {
      setStep(STEPS.STEP_VOICE_TONE)
    }
  }
  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '-100vw' }}
        transition={{ type: 'tween', duration: 0.5 }}
        style={{
          width: '100%',
          // height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.box_step}>
          <div className={styles.box_page}>
            <p style={{ margin: '0px' }}>Estamos quase lá</p>
            <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
              Deslize para selecionar as características da sua marca
            </h1>

            <div className={styles.box_input_bps}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 330,
                    gap: 20,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <p className={styles.number_parameter}>5</p>
                    <p className={styles.number_parameter}>4</p>
                    <p className={styles.number_parameter}>5</p>
                    <p className={styles.number_parameter}>2</p>
                    <p className={styles.number_parameter}>1</p>

                    <p className={styles.number_parameter}>0</p>

                    <p className={styles.number_parameter}>1</p>
                    <p className={styles.number_parameter}>2</p>
                    <p className={styles.number_parameter}>3</p>
                    <p className={styles.number_parameter}>4</p>
                    <p className={styles.number_parameter}>5</p>
                  </div>

                  {SliderValueComponent({
                    leftText: 'Feminino',
                    rightText: 'Masculino',
                    sliderValue: brandingArchetypeData?.bpsFemaleOrMale || 0,

                    setSliderValue: (value) =>
                      handleChange('bpsFemaleOrMale', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Simples',
                    rightText: 'Complexo',
                    sliderValue: brandingArchetypeData?.bpsSimpleOrComplex || 0,

                    setSliderValue: (value) =>
                      handleChange('bpsSimpleOrComplex', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Sutil',
                    rightText: 'Extravagante',
                    sliderValue:
                      brandingArchetypeData?.bpsSubtleOrExtravagant || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsSubtleOrExtravagant', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Clássico',
                    rightText: 'Moderno',
                    sliderValue: brandingArchetypeData?.bpsClassicOrModern || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsClassicOrModern', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Amigável',
                    rightText: 'Distante',
                    sliderValue:
                      brandingArchetypeData?.bpsFriendlyOrDistant || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsFriendlyOrDistant', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Brincalhão',
                    rightText: 'Sério',
                    sliderValue:
                      brandingArchetypeData?.bpsPlayfulOrSerious || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsPlayfulOrSerious', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Natural',
                    rightText: 'Industrial',
                    sliderValue:
                      brandingArchetypeData?.bpsNaturalOrIndustrial || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsNaturalOrIndustrial', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Popular',
                    rightText: 'Elitizado',
                    sliderValue: brandingArchetypeData?.bpsPopularOrElite || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsPopularOrElite', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Despojado',
                    rightText: 'Sofisticado',
                    sliderValue:
                      brandingArchetypeData?.bpsStrippedOrSophisticated || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsStrippedOrSophisticated', value),
                  })}

                  {SliderValueComponent({
                    leftText: 'Rústico',
                    rightText: 'Refinado',
                    sliderValue: brandingArchetypeData?.bpsRusticOrRefined || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsRusticOrRefined', value),
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.box_button}>
            <button
              className={styles.back_button}
              onClick={() => handleNavigate('back')}
            >
              Voltar
            </button>
            <button
              className={styles.next_button}
              onClick={() => handleNavigate('next')}
            >
              Avançar
            </button>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default StepBPS
