import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { BrandingService, BucketUploadService, FontService } from '../../../services'
import { motion } from 'framer-motion'
import WebFont from 'webfontloader'
import Loading from '../../../components/Loading'
import { notifyErrorCustom } from '../../../utils/notify'



const STEPS = {
  STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  STEP_PASS_LOGO: 'stepLogo',
}

function StepTypography({ setStep, businessAccountData, font, setFont }) {
  const [textIA, setTextIA] = useState('')
  const [textExemple, setTextExemple] = useState('')
  const [fontsOptions, setFontsOptions] = useState([])

  const [branding, setBranding] = useState()

  const [filesSelectedArray, setFilesSelectedArray] = useState([])

  const [loading, setLoading] = useState(false)


  const handleNavigate = async (type) => {
    if (type === 'next') {

      if(font === ''){
        notifyErrorCustom('Escolha uma fonte para sua arte')
        return
      }
      //
      saveFont()
      setStep(STEPS.STEP_PASS_LOGO)
    } else {
      setStep(STEPS.STEP_PASS_COLOR_PALETTE)
    }
  }

 

  useEffect( () => {
    // if (dataToCompare)
    
    loadFonts()
    loadBrandin()
    //setFont(fontData.data)
  }, [])

  

  const loadBrandin = async () =>{
    const { data } = await BrandingService.getAllBrandingByBusinessId(businessAccountData.data.uuid)
    setBranding(data[0])
  }

  const [selectedFontIndex, setSelectedFontIndex] = useState(null); // Estado para armazenar o índice da fonte selecionada

  const handleFontClick = (index, font) => {
    setFont(font?.value)
    setSelectedFontIndex(index); // Atualiza o índice da fonte selecionada
  };

  const saveFont = async () =>{
    const {data: dataFont} = await BucketUploadService.getGoogleFontsBucketByName(
      filesSelectedArray[0].name
    )

    const fontsToUpdate =   await formatFonts(filesSelectedArray)

    
    const brandinId = branding.uuid
       
      
     delete branding.uuid
     delete branding.businessId
     delete branding.createdAt
     delete branding.updatedAt
     delete branding.deletedAt
     delete branding.fontsBase64


    const dataToSubmit = {
      ...branding,
      ...fontsToUpdate
    }


    const { responseStatus } = await BrandingService.putBranding(
      brandinId,
      dataToSubmit
   
    )
    

  }

  const formatFonts = async (fonts) => {
    let fontsUploadObj = {}

    fontsUploadObj['fontSubtitleId'] = ''
    fontsUploadObj['fontSubtitleByUploaded'] = false
    fontsUploadObj['fontTitleId'] = ''
    fontsUploadObj['fontTitleByUploaded'] = false
    fontsUploadObj['fontTextId'] = ''
    fontsUploadObj['fontTextByUploaded'] = false

    for (const fonte of fonts) {
      const objToGetId = {
        name: fonte.name,
        data: fonte.fileDataBase64,
      }
      const { data } = await FontService.postExternalFontsGoogle(objToGetId)

      // if (fonte.selectedFontType?.value == 2) {
        fontsUploadObj['fontSubtitleId'] = data.uuid
        fontsUploadObj['fontSubtitleByUploaded'] = false
      // }

      // if (fonte.selectedFontType?.value == 1) {
        fontsUploadObj['fontTitleId'] = data.uuid
        fontsUploadObj['fontTitleByUploaded'] = false
      // }

      // if (fonte.selectedFontType?.value == 3) {
        fontsUploadObj['fontTextId'] = data.uuid
        fontsUploadObj['fontTextByUploaded'] = false
      // }
    }

    return fontsUploadObj
  }

  const loadFonts = async () => {
    setLoading(true)
    let googleFontsBucket = await BucketUploadService.getGoogleFontsBucket()

    setFontsOptions(
      googleFontsBucket.data.map((e) => ({ label: e.name, value: e.name }))
    )
    setLoading(false)
  }

  let importFont = ''


  const handleClickSetFont = (index, font) => {

    setFont(font?.value)

    const fontArrray = []

    const dataArray = {
      fileDataBase64: font.value,
      isSystemFont: true,
      name: font.value,
      selectedFontType :{
        label : 'Título',
        value : 1
      },
    }

    fontArrray.push(dataArray)

    setSelectedFontIndex(index)
    // const aux = fontArrray
    // //if (aux.length >= 3 || !font) return
    // aux.push({ ...font })

    // const fontFamilies = aux
    //   // .filter((font) => !font.isSystemFont)
    //   .map((font) => font.name)

    // if (fontFamilies.length > 0) {
    //   WebFont.load({
    //     google: {
    //       families: fontFamilies,
    //     },
    //   })
    // }
    setFilesSelectedArray(fontArrray)

    //renderSelectedFontOnDocument()

    // const filteredTypeOptions = fontTypeOptions.filter(
    //   (e) => e.value != selectedFontType.value
    // )
    // setFontTypeOptions(filteredTypeOptions)

    // setSelectedFont(undefined)
    // setSelectedFontType(undefined)
    // setSelectedFontItem(undefined)
    // setFontUpload(false)
  }
  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '-100vw' }}
        transition={{ type: 'tween', duration: 0.5 }}
        style={{
          width: '100%',
          // height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.box_step}>
          <style>
            {importFont}
          </style>
          <div className={styles.box_page}>
            <p style={{ margin: '0px' }}>Tipografia</p>
            <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Fonte de Texto</h1>

            <div className={styles.box_content_page}>
              <div className={styles.box_input_search}>
                <InputIA
                  placeholder={'Digite aqui o que deseja'}
                  text={textIA}
                  setText={setTextIA}
                  setLoading={() => {}}
                  clickFunction={() => {}}
                />
              </div>

              <div className={styles.box_selects}>
                <input
                  value={textExemple}
                  onChange={(e) => setTextExemple(e.target.value)}
                  className={styles.input_label}
                  type="text"
                  placeholder="Insira seu texto "
                />
                <SelectRound
                  htmlFor="select"
                  placeholder="Selecione"
                  defaultSelected={{ label: 'Categoria', value: '' }}
                  options={[
                    { label: 'Teste', value: 1 },
                    { label: 'Teste2', value: 2 },
                  ]}
                />
              </div>

              <div className={styles.container_box_font}>
                {fontsOptions.map((font, index) => (
                  <div 
                  // className={styles.box_font} key={index}
                    className={
                      index === selectedFontIndex
                        ? styles.box_font_select // Adiciona uma classe extra se for a fonte selecionada
                        : styles.box_font
                    }
                    key={index}
                    onClick={() => handleClickSetFont(index, font)} // Atualiza o estado com o índice da fonte clicada
                  >
                  
                    <p className={ index === selectedFontIndex ? styles.font_title_text_selected: styles.font_title_text  }>{font.label}</p>

                    <div className={  index === selectedFontIndex ? styles.box_font_select_text :styles.box_font_text }>
                      <h1
                        className={styles.font_text}
                        style={{ fontFamily: `${font.value}` }}
                      >
                        {textExemple ||
                          'Whereas disregard and contempt for human rights have resulted. Lorem Ipsum dolor it damet setinels'}
                      </h1>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.box_button}>
            <button
              className={styles.back_button}
              onClick={() => handleNavigate('back')}
            >
              Voltar
            </button>
            <button
              className={styles.next_button}
              onClick={() => handleNavigate('next')}
            >
              Avançar
            </button>
          </div>
        </div>
      </motion.div>
    </>

  )
}

export default StepTypography
