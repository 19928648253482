import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import Logo from '../../../assets/logos/postei-logo-color.png'
import { motion } from 'framer-motion'
import { UserService } from '../../../services'

function StepSubject({ setSubject, subject, setStep }) {
  const STEPS = {
    STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
    STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (subject === '') {
        notifyErrorCustom('Preencha o campo com o assunto de sua empresa')
        return
      }

      setStep(STEPS.STEP_PASS_COLOR_PALETTE)
    } else {
      setStep(STEPS.STEP_PASS_NICHE_AND_OBJECTIVE)
    }
  }
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>Cadastro</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Criar sua publicação</h1>

          <div className={styles.box_input_email}>
            <p
              style={{
                'font-family': 'Poppins',
                'font-size': '16px',
                'font-weight': '500',
                'line-height': '24px',
                'text-align': 'left',
              }}
            >
              Descreva o assunto da sua publicação
            </p>

            <div className={styles.container_imput_component}>
              <div className={styles.box_logo_imput}>
                <img src={Logo} className={styles.logo_imput} />
              </div>
              <input
                className={styles.input_email}
                type="text"
                value={subject}
                placeholder="Seu breve conteúdo..."
                onChange={(e) => setSubject(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            Voltar
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            Avançar
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepSubject
