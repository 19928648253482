import { useState } from 'react'
import styles from './styles.module.css'
import {
  CloundArrowUp,
  Interrogation,
  Stars,
  Trash,
} from '../../../assets/svgs/icons'
import { PythonService } from '../../../services'
import { notifyErrorCustom } from '../../../utils/notify'
import { motion } from 'framer-motion'

const STEPS = {
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_PASS_LOGO: 'stepLogo',
}
function StepImage({ setStep, filesSelectedArray, setFilesSelectedArray }) {
  const handleRemoveBackground = async (index, base64) => {
    const { data } = await PythonService.postRemoveBackground({ image: base64 })
    const noBgImage = data.data.images

    filesSelectedArray[index].fileDataBase64 = noBgImage[1]?.image
    filesSelectedArray[index].isOriginal = noBgImage[1]?.isOriginal

    setFilesSelectedArray([...filesSelectedArray])
  }

  const onDragOver = (event) => {
    event.preventDefault()
    // setDropzoneHover(true)
  }

  const onDragLeave = () => {
    // setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setFilesSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
        }

        setFilesSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function formatBytes(bytes, decimals = 0) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const handleRemoveFile = (index) => {
    const updatedFilesArray = filesSelectedArray.filter((_, i) => i !== index)

    setFilesSelectedArray(updatedFilesArray)
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      
      if (filesSelectedArray.length === 0) {
        notifyErrorCustom('Escolha uma imagem para geração de arte')
        return
      }
      // else {
      // }
      setStep(STEPS.STEP_VOICE_TONE)
    } else {
      setStep(STEPS.STEP_PASS_LOGO)
    }
  }
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>Imagem Adicional</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Adicione uma imagem na sua arte</h1>

          <div className={styles.container_modal}>
            <div className={styles.content_up_image}>
              <div className={styles.modal_container_drag}>
                <div className={styles.modal_drag}>
                  {/* <label htmlFor="arquivo">
                <h3>Arrase & Solte o arquivo</h3>
                <p>Ou selecione o arquivo no explorador</p>
                </label> */}

                  <div
                    className={styles.modal_drag_drop}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    // onClick={handleShowListImages}

                    // style={{
                    //   height: '100px',
                    //   width: '100%',
                    //   border: '2px dashed black',
                    //   backgroundColor: dropzoneHover ? 'blue' : 'red',
                    // }}
                    // className={styles.modal_drag}
                  >
                    <label htmlFor="arquivo">
                      <CloundArrowUp color={'#B900FE'} />
                      <h2
                        className={
                          filesSelectedArray.length !== 0
                            ? styles.modal_drag_drop_title_short
                            : styles.modal_drag_drop_title
                        }
                      >
                        Arraste & Solte o arquivo
                      </h2>
                      <p
                        className={
                          filesSelectedArray.length !== 0
                            ? styles.modal_drag_drop_sub_title_short
                            : styles.modal_drag_drop_sub_title
                        }
                      >
                        Ou selecione o arquivo no explorador
                      </p>
                    </label>
                    <input
                      type="file"
                      name="arquivo"
                      id="arquivo"
                      onChange={(e) => onFileChange(e)}
                    ></input>
                    {/* {fileData && (
                      <img
                      style={{ width: 50, height: 50 }}
                      src={fileData}
                      alt="file-preview"
                      />
                      )} */}
                  </div>
                </div>
              </div>

              {filesSelectedArray.length !== 0 && (
                <div className={styles.content_list_image}>
                  <div className={styles.container_box_list_image}>
                    {filesSelectedArray?.map((image, index) => (
                      <div
                        key={index}
                        className={styles.container_list_image}
                        // style={{ border: '1px solid red' }}
                      >
                        <div className={styles.item_list_image}>
                          <img
                            src={image.fileDataBase64}
                            alt="file-preview"
                            style={{
                              width: 40,
                              height: 40,
                              objectFit: 'cover',
                            }}
                          />
                          <div className={styles.box_data_info}>
                            <p className={styles.box_data_info_title}>
                              {image.name}
                            </p>
                            <p className={styles.box_data_info_size}>
                              Tamanho &nbsp;<b> {formatBytes(image.size)} </b>
                            </p>
                          </div>
                        </div>
                        <div className={styles.item_list_image_buttons}>
                          <button
                            className={styles.button_cancel}
                            onClick={() => handleRemoveFile(index)}
                          >
                            <p className={styles.button_cancel_title}>
                              Excluir
                            </p>
                            <Trash />
                          </button>
                          <button
                            className={styles.button_remove_background}
                            onClick={() =>
                              handleRemoveBackground(
                                index,
                                image.fileDataBase64
                              )
                            }
                          >
                            <p
                              className={styles.button_remove_background_title}
                            >
                              Remover Fundo
                            </p>{' '}
                            <Stars />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={styles.modal_button_save}>
                <button className={styles.button_add}>
                  <label className={styles.button_add_label} htmlFor="arquivo">
                    Adicionar Imagem
                  </label>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            Voltar
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            Avançar
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepImage
