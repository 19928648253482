import styles from './styles.module.css'
import { AnimatePresence, motion } from 'framer-motion'
import { useProductPage } from './useProductPage'
import { ProductForm } from './ProductForm'
import { ProductsList } from './ProductList'
import ProductVizualization from './ProductVisualization'
import { HeaderProductPage } from './components/headerPage'



function ProductsPage() {
  const {
    step,
    setStep,
    headers,
    products,
    product,
    imagesToRender,
    productOptions,
    handleDeleteProduct,
    handleClickEdit,
    handleClickVisualize,
    handleClearSelectedProduct,
    handleChangeProductData,
    handleChangeImageToRender,
    handleExcludeImageRendered,
    handleRemoveBackground,
    handleSubmit,
    error,
  } = useProductPage()

  return (
    <div className={styles.container_page}>
      <motion.div
        // key={expanded}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ width: '100%' }}
      >
        <HeaderProductPage
          title={
            step === 0
              ? 'Lista de Produtos'
              : step === 1
              ? 'Novo Produto'
              : 'Gerenciando o Produto'
          }
          btnRightText={step === 2 ? 'Editar Informações' : undefined}
          onClickRightBtn={() => handleClickEdit(product)}
          btnLeftText={
            step === 0 ? 'Novo Produto' : step === 1 ? 'Cancelar' : 'Voltar'
          }
          onClickLeftBtn={() => handleClearSelectedProduct(step)}
        />

        <div className={styles.container_content}>
          <AnimatePresence initial={false}>
            {step === 0 && (
              <ProductsList
                headers={headers}
                products={products}
                onClickEdit={handleClickEdit}
                onClickDelete={handleDeleteProduct}
                onClickVisualize={handleClickVisualize}
              />
            )}
            {step === 1 && (
              <ProductForm
                product={product}
                productOptions={productOptions}
                error={error}
                imagesArray={imagesToRender}
                onChangeImage={handleChangeImageToRender}
                onChangeProductData={handleChangeProductData}
                onExcludeImage={handleExcludeImageRendered}
                onRemoveBackground={handleRemoveBackground}
                onSubmit={handleSubmit}
              />
            )}
            {step === 2 && (
              <ProductVizualization
                product={product}
                imagesArray={imagesToRender}
                onClickPrevious={() => setStep(0)}
              />
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </div>
  )
}

export default ProductsPage
