/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useEffect, useRef, useState } from 'react'
import { NavigateArrowRigth, ChevronDown } from '../../../../assets/svgs/icons'

export const SelectSquare = ({
  backgroundColor = '#e0e0e0',
  border,
  borderColor,
  options,
  label,

  htmlFor,
  placeholder,
  defaultSelected,
  arrowDirection = 'right',
  fontSize,
  onClickItem,
  round,
  color = '#0f172a',
  arrowColor = '#0f172a',
  padding = '10px 15px 10px 15px',
  radius = '10px',
  minWidth = '0px',
}) => {
  const [containerVisible, setContainerVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    label: '',
    value: '',
  })

  const handleSelect = (e) => {
    console.log('33', e)
    setContainerVisible(false)
    setSelectedItem(e)
    onClickItem(e)
  }

  useEffect(() => {
    console.log('optionsoptions', options)
    function handleClickOutside(event) {
      if (
        containerVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setContainerVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerVisible])

  const ref = useRef(null)

  useEffect(() => {
    setSelectedItem(defaultSelected)
  }, [defaultSelected])

  return (
    <div ref={ref} className={styles.Container_select}>
      <div className={styles.inputSelectContainer}>
        <label htmlFor={htmlFor}>{label}</label>
        <div
          style={{
            backgroundColor,
            border: border ? '1px solid ' : 'none',
            borderColor: borderColor ? borderColor : 'none',
            padding: padding,
            borderRadius: radius,
            minWidth: minWidth,
          }}
          className={round ? styles.buttonSelectRound : styles.buttonSelect}
          onClick={() => setContainerVisible((prev) => !prev)}
          type="text"
        >
          <p
            style={{ fontSize: fontSize, color: color }}
            className={styles.placeholder_select}
          >
            {selectedItem?.label || placeholder}
          </p>
          {/* <p>{value}</p> */}

          <div className={styles.box_svg}>
            <div
              className={` ${styles[arrowDirection]} ${
                containerVisible ? styles.rotate : styles.containerVisible
              }`}
            >
              {arrowDirection == 'right' && (
                <NavigateArrowRigth size="16" color={arrowColor} />
              )}
              {arrowDirection == 'down' && (
                <ChevronDown size="16" color={arrowColor} />
              )}
            </div>
          </div>
        </div>
        {containerVisible && (
          <div className={styles.selectContainer}>
            {options.map((option, index) => (
              <button
                key={index}
                className={styles.selectContainerItem}
                onClick={() => {
                  handleSelect(option)
                }}
              >
                <span>{option.label}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
