import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { UserGroup } from '../../../assets/svgs/icons'
import ColorPaletteComponent from './ColorPaletteComponent'
import { notifyErrorCustom } from '../../../utils/notify'
import { BrandingService } from '../../../services'
import { getBusinessByLogin } from '../../../services/business'
import { motion } from 'framer-motion'

const STEPS = {
  STEP_PASS_SUBJECT: 'StepSubject',

  STEP_PASS_TYPOGRAPHY: 'stepTypography',
}
function StepColorPalette({ setStep, colors, setColors, businessAccountData }) {
  
  const [branding, setBranding] = useState()


  useEffect(  () => {
    
    loadBranding()
  }, [])
  
  const loadBranding = async () => {
    
    const { data } = await BrandingService.getAllBrandingByBusinessId(businessAccountData.data.uuid)
    setBranding(data[0])
  }
  

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (colors.length < 3) {
        notifyErrorCustom('Escolha no minimo 3 cores')
        return
      }

      const responseBusines = await getBusinessByLogin()

      const hasEmptyField = colors.some((item) => !item)

      if (hasEmptyField) {
        notifyErrorCustom('Um campo ainda está em vazio')
        return
      }

      let colorsArray = {
        //businessId: responseBusines?.data[0]?.uuid,
        primaryColor: colors[0],
        secondaryColor: colors[1],
        tertiaryColor: colors[2] ?? '',
        complementaryColor1: colors[3] ?? '',
        complementaryColor2: colors[4] ?? '',

        fontTitleId: '',
        //fontTitleByUploaded: '',

        fontSubtitleId: '',
        //fontSubtitleByUploaded:'',

        fontTextId: '',
        //fontTextByUploaded: '',

        fontTitleByUploaded: false,
        fontSubtitleByUploaded: false,
        fontTextByUploaded: false,
      }

      
      if( branding){
        colorsArray ={
          primaryColor: colors[0],
          secondaryColor: colors[1],
          tertiaryColor: colors[2] ?? '',
          complementaryColor1: colors[3] ?? '',
          complementaryColor2: colors[4] ?? '',
          fontTitleId: branding.fontTitleId,
          //fontTitleByUploaded,

          fontSubtitleId: branding.fontSubtitleId,
          //fontSubtitleByUploaded:'',

          fontTextId: branding.fontTextId,
          //fontTextByUploaded,

          fontTitleByUploaded: branding.fontTitleByUploaded,
          fontSubtitleByUploaded: branding.fontSubtitleByUploaded,
          fontTextByUploaded:branding.fontTextByUploaded,
        }

        
        const { responseStatus } = await BrandingService.putBranding(
          branding.uuid,
          colorsArray
       
        )
        //return

      }
      else{

        await BrandingService.postBranding(colorsArray)
      }

      setStep(STEPS.STEP_PASS_TYPOGRAPHY)
    } else {
      setStep(STEPS.STEP_PASS_SUBJECT)
    }
  }

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>Cores</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Paleta de Cores</h1>
          <ColorPaletteComponent colors={colors} setColors={setColors} />
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            Voltar
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            Avançar
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepColorPalette
